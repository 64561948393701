import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useErrorBoundary } from "react-error-boundary";
import {
    answersAtom,
    assessmentIdAtom,
    demographicsAtom,
    languageAtom,
    loginCodeAtom,
    loginFormAtom,
    loginStatusAtom,
    progressionsAtom,
    respondentAtom,
    userAtom
} from "_atoms";
import { batchIdSelector } from "_selectors/batch";
import { setLoginStatusAfterFailedLogin } from "_helpers/login-helpers";
import { answersGetQuery } from "_queries/answers-queries";
import { respondentGetQuery } from "_queries/respondent-queries";
import { demographicsGetQuery } from "_queries/demographics-queries";
import { progressionsGetQuery } from "_queries/progressions-queries";

/**
* When the login code changes, check if it's time to fetch a respondent's info (respondent object, answers,
 * demographics, progressions) from the API
 */
const useLoginCode = () => {
    /** @type LoginCode */
    const loginCode = useRecoilValue(loginCodeAtom);
    /** @type string */
    const batchId = useRecoilValue(batchIdSelector);
    /** @type {Dispatch<SetStateAction<Answer[]>>} */
    const setAnswers = useSetRecoilState(answersAtom);
    /** @type {Dispatch<SetStateAction<Demographic[]>>} */
    const setDemographics = useSetRecoilState(demographicsAtom);
    /** @type {Dispatch<SetStateAction<string>>} */
    const setLanguage = useSetRecoilState(languageAtom);
    /** @type {Dispatch<SetStateAction<LoginStatus>>} */
    const setLoginStatus = useSetRecoilState(loginStatusAtom);
    /** @type {Dispatch<SetStateAction<Progression[]>>} */
    const setProgressions = useSetRecoilState(progressionsAtom);
    /** @type {Dispatch<SetStateAction<Respondent>>} */
    const setRespondent = useSetRecoilState(respondentAtom);
    /** @type {Dispatch<SetStateAction<User>>} */
    const setUser = useSetRecoilState(userAtom);
    /** @type function */
    const resetAnswers = useResetRecoilState(answersAtom);
    /** @type function */
    const resetAssessmentId = useResetRecoilState(assessmentIdAtom);
    /** @type function */
    const resetDemographics = useResetRecoilState(demographicsAtom);
    /** @type function */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type function */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);
    /** @type function */
    const resetProgressions = useResetRecoilState(progressionsAtom);
    /** @type function */
    const resetRespondent = useResetRecoilState(respondentAtom);
    /** @type function */
    const resetUser = useResetRecoilState(userAtom);

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    /**
     * Whenever the Login Code atom changes, check if it's time to use that info to log in
     */
    useEffect(() => {
        // When the respondent is already logged in (i.e., has a batch id), no need to log in again.
        if (batchId) return;

        /** @type AbortController */
        const respondentController = new AbortController();
        /** @type AbortController */
        const answersController = new AbortController();
        /** @type AbortController */
        const demographicsController = new AbortController();
        /** @type AbortController */
        const progressionsController = new AbortController();

        /**
         * Handle an error from an unsuccessful API call.
         * @param {AxiosError|AxiosResponse} axiosError - The error object returned by axios
         * @return {void}
         */
        function handleFailure(axiosError) {
            try {
                // Abort any outstanding API requests
                respondentController.abort();
                answersController.abort();
                demographicsController.abort();
                progressionsController.abort();

                // Set the login status
                const data = axiosError?.response?.data || axiosError?.data;
                setLoginStatusAfterFailedLogin(data, setLoginStatus);

                // Remove the session variable
                sessionStorage.removeItem('session');

                // Reset the app state
                resetAnswers();
                resetAssessmentId();
                resetDemographics();
                resetProgressions();
                resetRespondent();
                resetUser();
            } catch (error) {
                console.log({'error': error, 'axiosError': axiosError});
                const errorMessage = axiosError?.response?.data?.message || axiosError?.data?.message || axiosError;
                showBoundary(errorMessage);  //Display the nearest error boundary
            }
        }

        /**
         * After a successful API call for fetching a respondent object, update the respondent atom. If the respondent
         * doesn't yet have a language, and there's only 1 available language, set it.
         * @param {AxiosResponse} axiosResponse - The http response object from axios
         * @return {void}
         */
        function saveRespondent(axiosResponse) {
            /** @type {?string} - Check if there's only 1 language available for the login code. */
            const onlyAvailableLanguage = loginCode?.languages?.length === 1 ? loginCode?.languages[0] : null;
            /** @type {?string} - The langauge associated with the respondent */
            const respondentLanguage = axiosResponse?.data?.language;
            /** @type {?string} - The value of the language property to record */
            const newLanguage = respondentLanguage || onlyAvailableLanguage;
            /** @type {boolean} - Whether to run a PUT query to save the new language info to the API */
            const isSaved = respondentLanguage === newLanguage;

            /** @type Respondent */
            const respondent = (
                /** @type Respondent */
                {
                    ...axiosResponse.data,
                    language: newLanguage,
                    isSaved: isSaved,
                    user: axiosResponse.data.user.id
                }
            );

            setRespondent(respondent);
            setUser(axiosResponse.data.user);
            resetLoginForm();
        }

        /**
         * After a successful API call for fetching the respondent's answers, update the answers atom
         * @param {AxiosResponse} axiosResponse - The http response object from axios
         * @return {void}
         */
        function saveAnswers(axiosResponse) {
            // Set the isSaved property to true
            const answers = axiosResponse.data.results.map((answer) => {
                return (
                    /** @type Answer */
                    {...answer, isSaved: true}
                )
            })
            setAnswers(answers);
        }

        /**
         * After a successful API call for fetching the demographics, update the demographics atom
         * @param {AxiosResponse} axiosResponse - The http response object from axios
         * @return {void}
         */
        function saveDemographics(axiosResponse) {
            // Set the isSaved property to true
            const demographics = axiosResponse.data.results.map((demographic) => {
                return (
                    /** @type Demographic */
                    {...demographic, isSaved: true}
                )
            })
            setDemographics(demographics);
        }

        /**
         * After a successful API call for fetching the respondent's progressions, update the progressions atom
         * @param {AxiosResponse} axiosResponse - The http response object from axios
         * @return {void}
         */
        function saveProgressions(axiosResponse) {
            // Set the isSaved property to true
            const progressions = axiosResponse.data.results.map((progression) => {
                return (
                    /** @type Progression */
                    {...progression, isSaved: true}
                )
            })
            setProgressions(progressions);
        }

        if (!!loginCode?.respondent) {
            respondentGetQuery(loginCode.respondent, respondentController).then(saveRespondent).catch(handleFailure);
            answersGetQuery(loginCode.respondent, answersController).then(saveAnswers).catch(handleFailure);
            demographicsGetQuery(loginCode.respondent, demographicsController).then(saveDemographics).catch(handleFailure);
            progressionsGetQuery(loginCode.respondent, progressionsController).then(saveProgressions).catch(handleFailure);
        }

        // When the effect unmounts, abort any active API requests
        return () => {
            respondentController.abort();
            answersController.abort();
            demographicsController.abort();
            progressionsController.abort();
        };
    }, [batchId, loginCode, resetAnswers, resetAssessmentId, resetDemographics, resetLoginForm, resetLoginStatus,
        resetProgressions, resetRespondent, resetUser, setAnswers, setDemographics, setLoginStatus, setProgressions,
        setRespondent, setUser, showBoundary]);

    /**
     * When the login code specifies only a single available language, set the value of the language atom to it
     */
    useEffect(() => {
        if (loginCode?.languages?.length === 1) {
            setLanguage(loginCode?.languages?.[0]);
        }
    }, [loginCode, setLanguage]);
}

export { useLoginCode };